<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/about-page'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Seo Title <strong>*</strong></label>
                      <input
                        v-model="formData.seo_title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Seo Description <strong>*</strong></label
                      >
                      <input
                        v-model="formData.seo_description"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Title <strong>*</strong></label>
                      <input
                        v-model="formData.title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Description <strong>*</strong></label
                      >
                      <input
                        v-model="formData.description"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage"> Image <strong>*</strong></label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('img', $event)"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage">Sub Text <strong>*</strong></label>
                      <input
                        v-model="formData.img_sub_text"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">
                        Two Title <strong>*</strong></label
                      >
                      <input
                        v-model="formData.twoTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">
                        Two Description <strong>*</strong></label
                      >
                      <input
                        v-model="formData.twoDescription"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"> Vision <strong>*</strong></label>
                      <input
                        v-model="formData.vision"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"> Mission <strong>*</strong></label>
                      <input
                        v-model="formData.mission"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "About Page Update",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "About Page Update",
      items: [
        {
          text: "About Page",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        language_id: null,
        seo_title: null,
        seo_description: null,
        title: null,
        description: null,
        img: null,
        img_sub_text: null,
        twoTitle: null,
        twoDescription: null,
        vision: null,
        mission: null,
      },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.AboutPage.UpdateList + this.$route.params.id).then(
        (response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.formData["seo_title"] = response.data.order.data.seo_title;
            this.formData["seo_description"] =
              response.data.order.data.seo_description;
            this.formData["title"] = response.data.order.json.title;
            this.formData["description"] = response.data.order.json.description;
            this.formData["img_sub_text"] =
              response.data.order.json.img_sub_text;
            this.formData["twoTitle"] = response.data.order.json.twoTitle;
            this.formData["twoDescription"] =
              response.data.order.json.twoDescription;
            this.formData["vision"] = response.data.order.json.vision;
            this.formData["mission"] = response.data.order.json.mission;
          }
        }
      );
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.AboutPage.Update + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/about-page");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
